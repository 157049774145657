



















import ITitle from '@/models/title/ITitle'
import Vue from 'vue'
import StatGrid from '@/components/general/StatGrid.vue';

function hexToColor(hexy: string): string {
    if (hexy.startsWith('0x')) {
        const colorNum = parseInt(hexy.substring(2), 16);
        const alpha = (((colorNum >> 24) & 0xFF) / 255);
        const r = ((colorNum >> 16) & 0xFF);
        const g = ((colorNum >> 8) & 0xFF);
        const b = (colorNum & 0xFF);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return hexy;
}

export default Vue.extend({
    props: {
        title: Object as () => ITitle,
    },
    components: {
        StatGrid,
    },
    computed: {
        titleStyle(): string {
            const fg = `color: ${hexToColor(this.title.foregroundColor)}`;
            const bg = `text-shadow: 0px 0px 2px ${hexToColor(this.title.backgroundColor)}`;
            return `${fg};${bg};`;
        },
        barStyle(): string {
            const hexy = hexToColor(this.title.displayColor);
            return hexy ? `border-left-color: ${hexy};` : '';
        },
    },
    methods: {
    }
})
