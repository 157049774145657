








































































































import Vue from 'vue';
import LoadingErrorable from '@/models/util/LoadingErrorable';
import ShopProvider from '@/api/ShopProvider';
import Loader from "@/components/util/Loader.vue";
import IItemShop, { IItemShopTab, IItemShopTabEntry, PurchaseLimitType, ShopLimitResetType } from '@/models/items/IItemShop';
import ItemIconTooltip from '@/old/items/ItemIconTooltip.vue';
import ItemCard from '@/old/items/ItemCard.vue';
import Points from '@/old/game/Points.vue';

Vue.component('point-tag', Points);

interface IData {
    shopData: LoadingErrorable<IItemShop, any>,
    tabIndex: number;
    pageNumber: number;
}

export default Vue.extend({
    props: {
        shopId: {
            type: Number as () => number,
        },
        useClick: {
            type: Boolean as () => boolean,
            default: false,
        },
        highlight: {
            type: Array as () => number[],
            default() {
                return [];
            },
        }
    },
    components: {
        Loader,
        ItemIconTooltip,
        ItemCard,
    },
    data(): IData {
        return {
            shopData: new LoadingErrorable(),
            tabIndex: 0,
            pageNumber: 0,
        };
    },
    watch: {
        shopId() {
            this.load();
        }
    },
    computed: {
        numPages(): number {
            if (this.shopData.value) {
                const tab = this.currentTab;
                if (tab) {
                    return Math.max(1, Math.ceil(Math.max(...tab.entries.map((r) => r.index)) / 30));
                }
            }

            return 0;
        },
        currentTab(): IItemShopTab|null {
            if (this.shopData.value) {
                return this.shopData.value.tabs.find((t) => t.id === this.tabIndex) || null;
            }

            return null;
        },
        currentTabItems(): IItemShopTabEntry[] {
            const tab = this.currentTab;
            if (tab) {
                return tab.entries;
            }

            return [];
        },
        currentPageItems(): IItemShopTabEntry[] {
            return this.currentTabItems.filter((r) => r.index >= (this.pageNumber * 30) && r.index < ((this.pageNumber + 1) * 30));
        },
        currentPageSlots(): (IItemShopTabEntry|null)[] {
            const ret = [];
            for (let i = 0; i < 30; ++i) {
                const idx = (this.pageNumber * 30) + i;
                ret[i] = this.currentPageItems.find((r) => r.index === idx) || null;
            }

            return ret;
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            if (this.shopData.loading) {
                return;
            }

            this.shopData.startLoad();
            try {
                this.shopData.done(await ShopProvider.getShop(this.shopId));
                if (this.shopData.value && this.shopData.value.tabs[0]) {
                    this.tabIndex = this.shopData.value!.tabs[0].id;
                }
            } catch (error) {
                this.shopData.failed(error);
            }
        },
        setTab(idx: number) {
            this.tabIndex = idx;
            this.pageNumber = 0;
        },
        setPage(idx: number) {
            this.pageNumber = idx;
        },
        limitString(entry: IItemShopTabEntry): string {
            switch (entry.limitReset) {
                case ShopLimitResetType.DAILY:
                    return `${entry.limitCount} per day`;
                case ShopLimitResetType.DAILY_ACCOUNT:
                    return `${entry.limitCount} per day per account`;
                case ShopLimitResetType.WEEKLY:
                    return `${entry.limitCount} per week`;
                case ShopLimitResetType.WEEKLY_ACCOUNT:
                    return `${entry.limitCount} per week per account`;
                default:
                    return `${entry.limitCount} per unknown:${entry.limitReset}`;
            }
        },
        clickItem(ud: IItemShopTabEntry) {
            this.$emit('click', ud);
        }
    }
});
