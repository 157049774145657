

































































import { CashFilter } from '@/api/ItemsProvider';
import { WeaponType, WeaponTypeName } from '@/models/items/ItemEnums';
import Vue from 'vue';

interface ISection {
    selected: boolean;
    options: {
        label: string;
        selected: boolean;
        id: number;
    }[];
}

interface IData {
    filters: {
        cash: ISection;
        nonCash: ISection;
        weapons: ISection;
    }
}

export default Vue.extend({
    data(): IData {
        return {
            filters: {
                cash: {
                    selected: true,
                    options: [
                        {
                            label: 'Helmet',
                            selected: true,
                            id: 0,
                        },
                        {
                            label: 'Body',
                            selected: true,
                            id: 1,
                        },
                        {
                            label: 'Leg',
                            selected: true,
                            id: 2,
                        },
                        {
                            label: 'Hand',
                            selected: true,
                            id: 3,
                        },
                        {
                            label: 'Foot',
                            selected: true,
                            id: 4,
                        },
                        {
                            label: 'Necklace',
                            selected: true,
                            id: 5,
                        },
                        {
                            label: 'Earring',
                            selected: true,
                            id: 6,
                        },
                        {
                            label: 'Ring',
                            selected: true,
                            id: 7,
                        },
                        {
                            label: 'Wing',
                            selected: true,
                            id: 9,
                        },
                        {
                            label: 'Tail',
                            selected: true,
                            id: 10,
                        },
                        {
                            label: 'Decal',
                            selected: true,
                            id: 11,
                        },
                        {
                            label: 'Spirit',
                            selected: true,
                            id: 12,
                        },
                    ],
                },
                nonCash: {
                    selected: true,
                    options: [
                        {
                            label: 'Face',
                            selected: true,
                            id: 0,
                        },
                        {
                            label: 'Hair',
                            selected: true,
                            id: 1,
                        },
                        {
                            label: 'Helmet',
                            selected: true,
                            id: 2,
                        },
                        {
                            label: 'Body',
                            selected: true,
                            id: 3,
                        },
                        {
                            label: 'Leg',
                            selected: true,
                            id: 4,
                        },
                        {
                            label: 'Hand',
                            selected: true,
                            id: 5,
                        },
                        {
                            label: 'Foot',
                            selected: true,
                            id: 6,
                        },
                        {
                            label: 'Necklace',
                            selected: true,
                            id: 7,
                        },
                        {
                            label: 'Earring',
                            selected: true,
                            id: 8,
                        },
                        {
                            label: 'Ring',
                            selected: true,
                            id: 9,
                        },
                    ],
                },
                weapons: {
                    selected: true,
                    options: Object.keys(WeaponTypeName).map((k) => ({
                        label: (WeaponTypeName as any)[k] as string,
                        selected: true,
                        id: Number(k),
                    })),
                },
            }
        };
    },
    mounted() {
        this.syncQuery();
    },
    watch: {
        $route() {
            this.syncQuery();
        },
    },
    computed: {
        notAll(): boolean {
            return !this.filters.nonCash.selected || !this.filters.cash.selected || !this.filters.weapons.selected;
        },
    },
    methods: {
        toggle(evt: InputEvent, group: number, id: number, isGroup: boolean) {
            const target = evt.target as HTMLInputElement;
            const selected = target.checked;
            if (isGroup) {
                if (group === 0) {
                    this.filters.nonCash.selected = selected;
                    this.filters.nonCash.options.forEach((o) => o.selected = selected);
                } else if (group === 1) {
                    this.filters.cash.selected = selected;
                    this.filters.cash.options.forEach((o) => o.selected = selected);
                } else if (group === 2) {
                    this.filters.weapons.selected = selected;
                    this.filters.weapons.options.forEach((o) => o.selected = selected);
                }
            } else {
                const groups = [this.filters.nonCash, this.filters.cash, this.filters.weapons];
                const g = groups[group];
                for (const t of g.options) {
                    if (t.id === id) {
                        t.selected = selected;
                        
                        g.selected = g.options.findIndex((o) => !o.selected) === -1;
                        
                        break;
                    }
                }
            }
            
            this.compute();
        },
        selectAll() {
            this.filters.nonCash.selected = true;
            this.filters.nonCash.options.forEach((o) => o.selected = true);
            this.filters.cash.selected = true;
            this.filters.cash.options.forEach((o) => o.selected = true);
            this.filters.weapons.selected = true;
            this.filters.weapons.options.forEach((o) => o.selected = true);
            this.compute();
        },
        selectNone(skipCompute = false) {
            this.filters.nonCash.selected = false;
            this.filters.nonCash.options.forEach((o) => o.selected = false);
            this.filters.cash.selected = false;
            this.filters.cash.options.forEach((o) => o.selected = false);
            this.filters.weapons.selected = false;
            this.filters.weapons.options.forEach((o) => o.selected = false);
            
            if (!skipCompute) {
                this.compute();
            }
        },
        compute() {
            // if all selected, return none
            if (this.filters.nonCash.selected && this.filters.cash.selected && this.filters.weapons.selected) {
                this.$emit('select', []);
                return;
            }
            
            const nonCashRet = this.filters.nonCash.options.filter((v) => v.selected).map((v) => v.id);
            const cashRet = this.filters.cash.options.filter((v) => v.selected).map((v) => -(v.id + 1));
            const weaponRet = this.filters.weapons.options.filter((v) => v.selected).map((v) => v.id + 100);
            
            this.$emit('select', [...nonCashRet, ...cashRet, ...weaponRet]);
        },
        syncQuery() {
            const q = this.$route.query;
            
            function extractNumericArray(v: any): number[] {
                if (v == null || v === '') {
                    return [];
                }
                
                return v.split(',').map(Number);
            }
            const rawParts = extractNumericArray(q.parts);
            if (rawParts.length === 0) {
                this.selectAll();
            } else {
                this.selectNone(true);
                let isAll = true;
                for (const k of this.filters.nonCash.options) {
                    const idx = rawParts.findIndex((v) => v === k.id);
                    if (idx !== -1) {
                        k.selected = true;
                    } else {
                        isAll = false;
                    }
                }
                
                if (isAll) {
                    this.filters.nonCash.selected = true;
                }
                
                isAll = true;
                for (const k of this.filters.cash.options) {
                    const idx = rawParts.findIndex((v) => v === -(k.id + 1));
                    if (idx !== -1) {
                        k.selected = true;
                    } else {
                        isAll = false;
                    }
                }
                
                if (isAll) {
                    this.filters.cash.selected = true;
                }
                
                isAll = true;
                for (const k of this.filters.weapons.options) {
                    const idx = rawParts.findIndex((v) => v === (k.id + 100));
                    if (idx !== -1) {
                        k.selected = true;
                    } else {
                        isAll = false;
                    }
                }
                
                if (isAll) {
                    this.filters.weapons.selected = true;
                }
                
                this.compute();
            }
        },
    }
})
