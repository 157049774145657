

































import Vue from 'vue'
import IExchangeType from '@/models/items/IExchangeType';
import ItemsProvider from '@/api/ItemsProvider';

interface IExchange extends IExchangeType {
    selected: boolean;
}

interface ICategory {
    id: number;
    label: string;
    types: IExchange[];
    selected: boolean;
}

interface IData {
    categories: ICategory[];
}

export default Vue.extend({
    
    data(): IData {
        return {
            categories: [],
        };
    },
    computed: {
        notAll(): boolean {
            return this.categories.findIndex((c) => !c.selected) !== -1;
        },
    },
    async mounted() {
        await this.loadCategories();
        this.syncQuery();
    },
    watch: {
        $route() {
            this.syncQuery();
        },
    },
    methods: {
        async loadCategories() {
            try {
                this.categories = [];
                const exchanges = await ItemsProvider.getExchangeTypes();
                
                for (const c of exchanges.filter((e) => e.type === 0)) {
                    this.categories.push({
                        id: c.category,
                        label: c.label,
                        types: exchanges.filter((t) => t.type !== 0 && t.category === c.category).map((v) => ({...v, selected: true})),
                        selected: true,
                    })
                }
            } catch (e) {
                console.error(e);
            }
        },
        toggle(evt: InputEvent, id: number, group: boolean) {
            try {
                const target = evt.target as HTMLInputElement;
                const selected = target.checked;
                for (const c of this.categories) {
                    if (group) {
                        if (c.id === id) {
                            c.selected = selected;
                            for (const t of c.types) {
                                t.selected = c.selected;
                            }
                            return;
                        }
                    } else {
                        for (const t of c.types) {
                            if (t.id === id) {
                                t.selected = selected;
                                // recompute parent if needed
                                if (t.selected) {
                                    // Are all the children selected? if not, clear category; otherwise add
                                    if (c.types.findIndex((v) => !v.selected) !== -1) {
                                        c.selected = false;
                                    } else {
                                        c.selected = true;
                                    }
                                } else {
                                    c.selected = false;
                                }
                                
                                return;
                            }
                        }
                    }
                }
            } finally {
                this.compute();
            }
        },
        selectAll(skipCompute = false) {
            for (const c of this.categories) {
                c.selected = true;
                for (const t of c.types) {
                    t.selected = true;
                }
            }
            
            if (!skipCompute) {
                this.compute();
            }
        },
        selectNone(skipCompute = false) {
            for (const c of this.categories) {
                c.selected = false;
                for (const t of c.types) {
                    t.selected = false;
                }
            }
            
            if (!skipCompute) {
                this.compute();
            }
        },
        compute() {
            // If all are selected, emit empty
            let allSelect = this.categories.findIndex((c) => !c.selected) === -1;
            if (allSelect) {
                this.$emit('select', []);
                return;
            }
            
            const ret = this.categories.flatMap((v) => v.types).filter((v) => v.selected).map((v) => v.id);
            this.$emit('select', ret);
        },
        syncQuery() {
            const q = this.$route.query;
            
            function extractNumericArray(v: any): number[] {
                if (v == null || v === '') {
                    return [];
                }
                
                return v.split(',').map(Number);
            }
            const rawCategories = extractNumericArray(q.c);
            if (rawCategories.length === 0) {
                this.selectAll();
            } else {
                this.selectNone(true);
                this.categories.forEach((cat) => {
                    const idx = rawCategories.findIndex((v) => v === cat.id);
                    if (idx !== -1) {
                        cat.selected = true;
                        for (const t of cat.types) {
                            t.selected = true;
                        }
                    } else {
                        let isAll = true;
                        for (const t of cat.types) {
                            const tidx = rawCategories.findIndex((v) => v === t.id);
                            if (tidx !== -1) {
                                t.selected = true;
                            } else {
                                isAll = false;
                            }
                        }
                        
                        if (isAll) {
                            cat.selected = true;
                        }
                    }
                });
                
                this.compute();
            }
        },
    }
})
