









import Vue from 'vue';
import ShopBrowser from './ShopBrowser.vue';

interface IData {
    shopId: number;
}

export default Vue.extend({
    components: {
        ShopBrowser,
    },
    data(): IData {
        return {
            shopId: 0,
        };
    },
})
